import $ from 'jquery';
import '/node_modules/slick-slider/slick/slick.min.js';
    
window.jQuery = window.$ = $;

jQuery(function(){

    if( jQuery('.rhardware-featured').length ) {
        jQuery('.rhardware-featured').slick({
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 1000,
            autoplay: true,
            autoplaySpeed: 3000,
        });
    }

});